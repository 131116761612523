import { navigate } from 'gatsby';
import { globalHistory } from '@reach/router';
// import { captureException } from '@sentry/gatsby';

const setParam = (paramKey, paramValue) => {
  const searchParams = new URLSearchParams(globalHistory.location.search);

  searchParams.set(paramKey, paramValue);

  return searchParams.toString() ? `?${searchParams.toString()}` : '';
};

const removeParam = paramKey => {
  const searchParams = new URLSearchParams(globalHistory.location.search);

  searchParams.delete(paramKey);

  return searchParams.toString() ? `?${searchParams.toString()}` : '';
};

const handleParams = (
  paramKey,
  paramValue,
  action
) => {
  switch (action.toLowerCase()) {
    case 'add':
    case 'replace':
      return setParam(paramKey, paramValue);

    case 'remove':
      return removeParam(paramKey);

    default:
      console.log(
        `Illegal action of ${action} used with the handleParams function`,
        `${paramKey}=${paramValue}`
      );
      break;
  }
};

/**
 *
 * @param {string} paramKey the key of a parameter (what is BEFORE the '=')
 * @param {string} paramValue the value of a parameter (what is AFTER the '=')
 * @param {string} [action = 'add'] options include ['add', 'remove', 'replace']
 * @param {string} target optional - id of scroll to elem
 * @param {boolean} preventNavigation optional - prevents a call to reach router's navigate
 * @returns an update to the URL parameters that also replaces history
 */
export const updateParamsSilently = (
  paramKey,
  paramValue,
  action = 'add',
  target = null,
  preventNavigation = false
) => {
  const path = globalHistory.location.pathname;
  const newParams = handleParams(
    paramKey,
    paramValue,
    action
  );

  if (preventNavigation) {
    globalHistory.location.search = newParams;
    globalHistory.location.hash = target;
    window.history.replaceState(null, null, `${path}${newParams}${target ? `#${target}` : ''}`);
  } else {
    navigate(`${path}${newParams}${target ? `#${target}` : ''}`, {
      replace: true,
      state: { scrollY: window.scrollY }
    });
  }
};

/**
 *
 * @param {string} paramKeys
 * @returns an array of current parameters
 */
export const getParams = paramKeys => {
  const paramString = globalHistory.location.search;
  const params = new URLSearchParams(paramString);
  let paramValues = {};
  paramKeys.forEach(paramKey => {
    paramValues = { ...paramValues, [paramKey]: params.getAll(paramKey) };
  });

  return paramValues;
};

/**
 *
 * @param {object} event click event
 * Clears all parameters from the current URL
 */
export const clearParams = preventNavigation => {
  const path = globalHistory.location.pathname;

  if (preventNavigation) {
    globalHistory.location.pathname = path;
    globalHistory.location.search = '';
    globalHistory.location.hash = '';
    window.history.replaceState(null, null, path);
  } else {
    navigate(path, {
      replace: true,
      state: { scrollY: window.scrollY }
    });
  }
};
